import { Box, CircularProgress, Grid, IconButton, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import Navbar from '../../components/Navbar';
import { useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { green } from '@mui/material/colors';
import { API } from '../../api/api_setup';
import { useAuth } from '../../hooks/useAuth';
import DataDisplay from '../../components/DataDisplay';


const DbQueryRunner = () => {
    const navigate = useNavigate()
    const [disableRun, setDisableRun] = useState(false)
    const [loading, setLoading] = useState(false)
    const [value, setValue] = useState('');
    const [outputText, setOutputText] = useState('')
    const [columns, setColumns] = useState(null)
    const [rows, setRows] = useState(null)

    const clearAll = () => {
        setOutputText('')
        setColumns(null)
        setRows(null)
    }

    const getHighlightedText = () => {
        const selectedText = window.getSelection().toString();
        return selectedText
    };

    const { user, } = useAuth()
    const axiosConfig = {
        headers: {
            Authorization: `Bearer ${user.token}`
        }
    }

    const handleQueryResponse = (rows) => {
        if (rows.length === 0) {
            setOutputText('No rows found')
        } else {
            rows = rows.map((row, index) => {
                return {
                    id: index,
                    ...row
                }
            })
            setRows(rows)

            const columns = []
            for (const colName in rows[0]) {
                columns.push({
                    field: colName,
                    headerName: colName,
                    width: 150
                })
            }
            setColumns(columns)
        }
    }

    const handleRun = () => {
        clearAll()
        setDisableRun(true)
        setLoading(true)
        const query = getHighlightedText() ? getHighlightedText() : value

        API.post("/database_run_query", {
            query
        }, axiosConfig)
            .then(response => {
                if (response.data.data) {
                    handleQueryResponse(response.data.data)
                }
            })
            .catch(err => {
                if (err.response && err.response.data) {
                    setOutputText(err.response.data.message)
                } else if (err.message) {
                    setOutputText(err.message)
                }
            })
            .finally(() => {
                setLoading(false)
                setDisableRun(false)
            })
    }

    return (
        <Grid container>
            <Grid item lg={12} md={12}>
                <Navbar leftWidgetList={[
                    <IconButton
                        size="large"
                        color="inherit"
                        aria-label="home"
                        onClick={() => { navigate("/") }}
                        sx={{ marginLeft: '-10px' }}
                        key={0}
                    >
                        <HomeIcon sx={{ fontSize: 40 }} />
                    </IconButton>,
                    <Typography variant='h4' sx={{ marginLeft: '10px' }} key={1}>{"Query runner"}</Typography>
                ]} />
            </Grid>
            <Grid item lg={12} md={12} height={50} display={'flex'} justifyContent={'flex-start'} borderBottom={'1px solid lightgray'}>
                <IconButton onClick={handleRun} disabled={disableRun} title='Run Query'>
                    <PlayArrowIcon sx={{ color: green[500], fontSize: '40px' }} />
                </IconButton>
            </Grid>
            <Grid item lg={12} md={12} bgcolor={'white'}>
                <TextField
                    label="Query"
                    multiline
                    rows={6} // Number of visible rows
                    variant="outlined"
                    fullWidth
                    value={value}
                    sx={{
                        fontFamily: 'monospace'
                    }}
                    onChange={(e) => setValue(e.target.value)}
                />
            </Grid>
            <Grid item lg={12} md={12} height={570} paddingTop={'20px'}>
                {
                    loading ? <Box sx={{ height: 40, mt: 40, marginLeft: '45%' }}>
                        <CircularProgress size={100} />
                    </Box> : null
                }
                {
                    rows ?
                        <DataDisplay
                            columns={columns}
                            rows={rows}
                        /> : null
                }
                {
                    outputText ?
                        <Typography>{outputText}</Typography> : null
                }
            </Grid>
        </Grid>
    );
}

export default DbQueryRunner;
