import { DataGrid } from '@mui/x-data-grid';
import React from 'react';

const defaultSortModelChange = (model, details) => {

}


const DataDisplay = ({ 
  rows, 
  columns, 
  loading, 
  handleCellClick, 
  sortModel = [], 
  onSortModelChange = defaultSortModelChange, 
  sx={},
  pageSize=100,
  pageSizeOptions = [100,200],
  rowHeight = 52,
  disableSorting = false,
  disableFilter = false
}) => {
  return (
    <DataGrid
      sortModel={sortModel}
      onSortModelChange={onSortModelChange}
      onCellClick={handleCellClick}
      rows={rows}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: pageSize,
          },
        },
      }}
      disableColumnSorting={disableSorting}
      disableColumnFilter={disableFilter}
      showCellVerticalBorder={true}
      pageSizeOptions={pageSizeOptions}
      disableRowSelectionOnClick
      loading={loading}
      slotProps={{
        loadingOverlay: {
          variant: 'linear-progress',
          noRowsVariant: 'linear-progress',
        },
      }}
      sx={sx}
      rowHeight={rowHeight}
    />
  );
}

export default DataDisplay;