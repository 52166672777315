import React, { useEffect, useState } from 'react';
import { alpha, Grid, InputBase, styled } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Navbar from '../../components/Navbar';
import { API } from '../../api/api_setup';
import DataDisplay from '../../components/DataDisplay';
import { countFormatterWithCommas } from '../../utils/helpers';
import NumberDisplayCard from '../../components/NumberDisplayCard';
import { setClusters, setSortModel, setSyncInfo } from './dashboardSlice';
import { HomePageColumns } from '../../utils/constants'
import { useAuth } from '../../hooks/useAuth';
import { useSnackbar } from '../../hooks/useSnackbar';
import { useNavigate } from 'react-router-dom';
import InfoDisplayCard from '../../components/InfoDisplayCard';
import DrawerMenu from '../../components/DrawerMenu';
import SearchIcon from '@mui/icons-material/Search';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));



const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(true)
  const { user, logout } = useAuth()
  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${user.token}`
    }
  }
  const sortModel = useSelector((state) => state.dashboard.sortModel)
  const clusters = useSelector((state) => state.dashboard.clusters)
  const syncInfo = useSelector((state) => state.dashboard.syncInfo)
  const [searchCluster, setSearchCluster] = useState("")
  const dispatch = useDispatch()
  const { showSnackbar } = useSnackbar()
  const navigate = useNavigate()


  const handleSortModelChange = (model, details) => {
    dispatch(setSortModel(model))
  }
  const get_total_scanned_count = () => {
    let total = 0;
    for (const item of clusters) {
      total += item.overallScanned
    }
    return countFormatterWithCommas(total)
  }
  const handleCellClick = (params, event, details) => {

  }
  const getClustersData = () => {
    if (!searchCluster)
    return clusters
    else {
      return clusters.filter(({clusterName}) => {
        return clusterName.includes(searchCluster)
      })
    }
  }


  useEffect(() => {
    API.get("/sync/last", axiosConfig)
      .then((response) => {
        dispatch(setSyncInfo(response.data.data))
      })
      .catch(err => {

      })

    if (clusters && clusters.length > 0) {
      setIsLoading(false)
      return
    }

    API.get('/cluster/overview', axiosConfig)
      .then((response) => {
        dispatch(setClusters(response.data.data))
        setIsLoading(false)
      })
      .catch(err => {
        showSnackbar("Something went wrong !!")
        logout()
        navigate("/")
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={12} md={12}>
          <Navbar rightWidgetList={[
            <DrawerMenu />,
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                value={searchCluster}
                onChange={(e) => {
                  setSearchCluster(e.target.value)
                }}
                placeholder="Search cluster…"
                inputProps={{ 'aria-label': 'search' }}
              />
            </Search>
          ]} />
        </Grid>
        <Grid item lg={12} md={12} sx={{ display: 'flex', alignItems: 'start' }}>
          <NumberDisplayCard color={'blue'} variant='h3' infoText={'Total Scanned Count across all Clusters'} count={get_total_scanned_count()} />
          <InfoDisplayCard variant='h5' infoList={syncInfo} />
        </Grid>
        <Grid item lg={12} md={12} sx={{ maxHeight: '70vh', minHeight: '20vh' }}>
          <DataDisplay
            rows={getClustersData()}
            columns={HomePageColumns}
            loading={isLoading}
            handleCellClick={handleCellClick}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
          />
        </Grid>
      </Grid>
    </>
  );
};


export default Dashboard;