import { Box, Breadcrumbs } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

const FileBreadCrumbs = ({ clusterName, }) => {
  const table_names = ['lb_analytics', 'struct_datasource_health']
  return (
    <Box >
      <Breadcrumbs separator="|" aria-label="breadcrumb" sx={{color: 'white'}}>
        {
          table_names.map((tblName) => <Link
          key={tblName}
          style={{color: 'white', textDecoration: 'none'}}
          to={`/files/${clusterName}/${tblName}`}
          >{tblName}
          </Link>)
        }
      </Breadcrumbs>
    </Box>
  );
}

export default FileBreadCrumbs;
